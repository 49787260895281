import React from 'react'
import ImageMeta from '../ImageMeta'
import MarkdownViewer from '@bit/azheng.joshua-tree.markdown-viewer'

const FeaturedQuote = ({ quote, name, image}) => {
    return (
        <div className="featured-quote">
            <div className="featured-quote__icon">
                <ImageMeta
                    publicId={image}
                    cloudName="nuvolum"
                    width="auto"
                    responsive
                    responsiveUseBreakpoints="true"
                />
            </div>

            <p className="featured-quote__quote">
                {quote}
            </p>
            
            <MarkdownViewer className="featured-quote__name" markdown={name} />
        </div>
    )
}

export default FeaturedQuote
